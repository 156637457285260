import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { window } from 'browser-monads'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import { Facebook, Twitter, Linkedin, Printer, Mail } from 'react-feather';

import Layout from "../layouts/index"
import Grid from "../components/elements/grid"
import { SizedText } from "../components/elements/typography"
import SEO from "../components/elements/seo"


const shortcodes = { Link } // Provide common components here

// SOCIAL BUTTONS https://edvins.io/add-social-share-buttons-to-gatsby-blog


const Tags = ({tags}) => {

  const tagList = tags
    .filter(tag => tag !== 'all')
    .map(tag => (
      <li key={tag}>
        <Link css={{
          color: `#2059a8`,
          textDecoration: `none`,
          borderBottom: `1px solid transparent`,
          transition: `all 0.5s`,
          '&:hover': {
            borderBottom: `1px solid #182852`,
            color: `#182852`
          }  
        }}
          to={`/resources/wellness/articles?tag=${tag}`}>{tag}</Link>
          <span>,</span>
      </li>
    ))

  return (
    <div css={{marginTop: `1rem`}}>
      Tags:
      <ul css={{
        display: `inline-block`,
        listStyle: `none`,
        padding: `0`,
        margin: `0`,
        'li': {
          display: `inline-block`,
          marginLeft: `0.5rem`,
        },
        'li:last-of-type span': {
          display: `none`,
        }
      }}>
        {tagList}
      </ul>
    </div>
  )
}


const ShareButtons = ({ url, title, description }) => (
  <div css={{
    display: `flex`,
    alignItems: `center`,
    marginTop: `16px`,
    'button': {
      display: `flex`,
      alignItems: `center`,
      marginRight: `0.79rem`,
      transition: `all 0.2s ease-out`,
      '&:hover': {
        transform: `scale(1.15)`,
      },
      '&:last-child': {
        marginRight: `0`,
      },
    },
    'svg': {
      color: `#5f6c80`,
      height: `1.802rem`,
      width: `1.802rem`
    },
    


  }}>
    <FacebookShareButton url={url} quote={description}>
      <Facebook strokeWidth={1.25} />
    </FacebookShareButton>

    <TwitterShareButton url={url} title={description}>
      <Twitter strokeWidth={1.25} />
    </TwitterShareButton>

    <LinkedinShareButton url={url} title={title} summary={description}>
      <Linkedin strokeWidth={1.25} />
    </LinkedinShareButton>

    <button css={{backgroundColor: `transparent`, border: `none`, padding: `0px`, font: `inherit`, color: `inherit`, cursor: `pointer`}} onClick={window.print}><Printer strokeWidth={1.25} /></button>

    <EmailShareButton url={url} title={description}>
      <Mail strokeWidth={1.25} />
    </EmailShareButton>    
  </div>
  )


export default function PageTemplate({ data: { mdx } }) {

  const image = getImage(mdx.frontmatter.featuredImage)
  const url = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <Layout isSubMenuOpen={true}>

      <SEO
        title={`${mdx.frontmatter.title} - The Claxton Bank`}
        description={mdx.frontmatter.description}
        location={mdx.slug}
        ogImg='/images/social-graph/the-claxton-bank.jpg'
      />

      <div css={{
        maxWidth: `1140px`,
        margin: `0 auto`,
        position: `relative`,
      }}>
        <div css={{ width: `100%`, aspectRatio: `16 / 6`}}>
            <GatsbyImage image={image} layout="fullWidth" aspectRatio={6 / 1} alt="test" />  
        </div>

      <div css={{
                padding: `0 1rem`,
                marginBottom: `6rem`,
                '@media (min-width: 1140px)': {
                  padding: `0`
                }
      }}>
              <div>
                <small css={{color: `#5f6c80`}}>Last Updated {mdx.frontmatter.date} - {mdx.frontmatter.author}</small>

                <p css={{ marginTop: `3rem`}}>
                  <SizedText as="span" smallSize="2.281" largeSize="1.424">
                    {mdx.frontmatter.readTime} Minute Read  
                  </SizedText>
                </p>
                <ShareButtons url={url} title={mdx.frontmatter.title} description={mdx.frontmatter.description} />
                <Tags tags={mdx.frontmatter.tags} />

                <h1 css={{fontFamily: `Proxima-nova`,  margin: `3rem 0 1rem 0`, 'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
                  <SizedText as="span" smallSize="2.281" largeSize="4.624">
                    {mdx.frontmatter.title}
                  </SizedText>
                </h1>
                
                <p css={{fontFamily: `Proxima-nova`,  margin: `0 0 2rem 0`, 'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
                  <SizedText as="span" smallSize="2.281" largeSize="2.027">
                    {mdx.frontmatter.description}
                  </SizedText>
                </p>

              </div>

              <MDXProvider components={shortcodes}>
                <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
              </MDXProvider>
      </div>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      slug
      frontmatter {
        title
        description
        tags
        date
        author
        readTime
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP]  
            )
          }
        }
      }
    }
  }
`